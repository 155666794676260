import React, { useEffect, useState } from 'react';
import Alert from '@material-ui/lab/Alert';
import { Panel } from '@michelin/acid-components';
import { useTranslation } from '@michelin/central-provider';
import { getOptionLabel } from '@michelin/select-options-provider';
import { UseQueryResult } from '@tanstack/react-query';
import { CONTENT_AREA_HEIGHT, CONTENT_AREA_HEIGHT_2WARNING, CONTENT_AREA_HEIGHT_WARNING } from 'components/Util';
import { primSecPriorityLevels } from 'hooks/useContacts';

interface WarningProps {
  primSecWarning: UseQueryResult<primSecPriorityLevels | null, unknown>;
  setHeightTable: (value: React.SetStateAction<string>) => void;
}

export const Warnings: React.FC<WarningProps> = ({ primSecWarning, setHeightTable }) => {
  const { t } = useTranslation();

  const [ERSManagerWarning, setERSManagerWarning] = useState<{
    warning: boolean;
    message: string;
    level: 'warning' | 'error' | undefined;
  }>({ warning: false, message: '', level: undefined });
  const [POIssuerWarning, setPOIssuerWarning] = useState<{
    warning: boolean;
    message: string;
    level: 'warning' | 'error' | undefined;
  }>({ warning: false, message: '', level: undefined });
  const warningMessage = (
    level: 'primary' | 'secondary' | 'both',
    type: 'tire' | 'mechanical',
    role: 'po_issuer' | 'ers_manager',
  ): string => {
    let levelMessage = '';
    if (level === 'both') {
      levelMessage = `${getOptionLabel('dealer_dealer_contact_types_levels', 'primary')} and ${getOptionLabel(
        'dealer_dealer_contact_types_levels',
        'secondary',
      )}`;
    } else {
      levelMessage = `${getOptionLabel('dealer_dealer_contact_types_levels', level)}`;
    }
    return t(
      `This account does not have assigned {{level}} contact for {{type}} - {{role}} contact type. Please create a contact or set an existing one.`,
      {
        level: levelMessage,
        role: getOptionLabel('contact_types', role).toUpperCase(),
        type: getOptionLabel('contact_types_services', type).toUpperCase(),
      },
    );
  };

  useEffect(() => {
    if (primSecWarning.data && !primSecWarning.isRefetching) {
      const { tire } = primSecWarning.data;
      const { ers_manager, po_issuer } = tire;
      if (!ers_manager.primary && !ers_manager.secondary) {
        setERSManagerWarning({
          warning: true,
          message: warningMessage('both', 'tire', 'ers_manager'),
          level: 'error',
        });
      } else if (!ers_manager.primary) {
        setERSManagerWarning({
          warning: true,
          message: warningMessage('primary', 'tire', 'ers_manager'),
          level: 'error',
        });
      } else if (!ers_manager.secondary) {
        setERSManagerWarning({
          warning: true,
          message: warningMessage('secondary', 'tire', 'ers_manager'),
          level: 'warning',
        });
      } else {
        setERSManagerWarning({ warning: false, message: '', level: undefined });
      }

      if (!po_issuer.primary && !po_issuer.secondary) {
        setPOIssuerWarning({
          warning: true,
          message: warningMessage('both', 'tire', 'po_issuer'),
          level: 'error',
        });
      } else if (!po_issuer.primary) {
        setPOIssuerWarning({
          warning: true,
          message: warningMessage('primary', 'tire', 'po_issuer'),
          level: 'error',
        });
      } else if (!po_issuer.secondary) {
        setPOIssuerWarning({
          warning: true,
          message: warningMessage('secondary', 'tire', 'po_issuer'),
          level: 'warning',
        });
      } else {
        setPOIssuerWarning({ warning: false, message: '', level: undefined });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [primSecWarning.data, primSecWarning.isRefetching]);

  useEffect(() => {
    if (ERSManagerWarning.warning && POIssuerWarning.warning) {
      setHeightTable(CONTENT_AREA_HEIGHT_2WARNING);
    } else if (ERSManagerWarning.warning || POIssuerWarning.warning) {
      setHeightTable(CONTENT_AREA_HEIGHT_WARNING);
    } else {
      setHeightTable(CONTENT_AREA_HEIGHT);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ERSManagerWarning.warning, POIssuerWarning.warning]);

  return (
    <>
      {(ERSManagerWarning.warning || POIssuerWarning.warning) && (
        <Panel spacing={0}>
          {ERSManagerWarning.warning && (
            <Alert
              severity={ERSManagerWarning.level}
              variant="standard"
              onClose={() => {
                setERSManagerWarning({ warning: false, message: '', level: undefined });
              }}
            >
              {ERSManagerWarning.message}
            </Alert>
          )}
          {POIssuerWarning.warning && (
            <Alert
              severity={POIssuerWarning.level}
              onClose={() => {
                setPOIssuerWarning({ warning: false, message: '', level: undefined });
              }}
            >
              {POIssuerWarning.message}
            </Alert>
          )}
        </Panel>
      )}
    </>
  );
};
