import React, { useState } from 'react';
import { Checkbox, Link } from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import { Add, ArrowBack, Delete, Timelapse } from '@material-ui/icons';
import { Fab, GlobalActions, useDialogs } from '@michelin/acid-components';
import { usePermissions, useTranslation } from '@michelin/central-provider';
import { ContactListingOVM } from '@michelin/fcp-view-models';
import { getOptionLabel } from '@michelin/select-options-provider';
import { useMutation } from '@tanstack/react-query';
import { deleteContacts } from 'hooks/useContacts';
import { TFunction } from 'i18next';
import { capitalize } from 'lodash';
import { useHistory } from 'react-router-dom';
import { getBaseUrl } from '../../../prefs-and-service-offers';
import { IAssigneeCustomerType } from '../type';
import {
  ERS_MANAGER_ROLE_AREA_VALUE,
  GENERAL_LEVEL_VALUE,
  PO_ISSUER_ROLE_AREA_VALUE,
  PRIMARY_LEVEL_VALUE,
  SECONDARY_LEVEL_VALUE,
} from '../utils';

interface RenderNameAndAccountInfoProps {
  row?: ContactListingOVM | null;
  contactMethod?: 'email' | 'work' | 'cell' | 'fax' | 'all';
}

export function renderContactInformation(row: ContactListingOVM, method: string = 'all'): JSX.Element {
  const icnStyle = { height: 16, verticalAlign: 'bottom', marginTop: 4 };
  const contactBlock = (data: string | null | undefined, Icon: any, defaultValue: string) => {
    if (method !== 'all' && method.trim().toLowerCase() !== defaultValue) return null;
    if (method === 'all' && (!data || !data.trim())) return null;
    return (
      <span style={{ whiteSpace: 'nowrap' }}>
        <Icon style={{ ...icnStyle, marginLeft: -6 }} />
        {(data || '').trim() || `${method} not set...`}
        {row.preferred_method && row.preferred_method.toLowerCase() === defaultValue ? (
          <Icons.Star style={icnStyle} />
        ) : null}
        <br />
      </span>
    );
  };

  let workPhone = row.work_phone;
  if (workPhone && row.ext) {
    workPhone += ` ext. ${row.ext}`;
  }
  return (
    <div style={{ marginTop: 2 }}>
      {contactBlock(row.cell_phone, Icons.PhoneAndroid, 'cell')}
      {contactBlock(workPhone, Icons.Phone, 'work')}
      {contactBlock(row.email_address, Icons.Email, 'email')}
      {method === 'fax' && row.fax ? contactBlock(row.fax, Icons.ReceiptTwoTone, 'fax') : null}
    </div>
  );
}
export function RenderNameAndAccountInfo(props: RenderNameAndAccountInfoProps) {
  const { row, contactMethod } = props;
  const history = useHistory();
  const permissions = usePermissions();
  if (!row) return null;
  const readPermission = permissions.allowsAction('contacts.read');

  return (
    <div>
      {readPermission ? (
        <Link
          onClick={() => history.push(`${getBaseUrl()}/contacts/${row.id || ''}/view`)}
          id={`link{${row.id}}`}
          style={{ cursor: 'pointer', fontWeight: 'bold' }}
        >
          {`${row.first_name} ${row.last_name}`}
        </Link>
      ) : (
        <b>{`${row.first_name} ${row.last_name}`}</b>
      )}
      {renderContactInformation(row, contactMethod)}
    </div>
  );
}

const nothingAssignedMessage = 'There are no priority levels assigned for this location.';

function internalRendering(contactTypes: Array<{ level: string; roleArea: string }> | undefined, t: TFunction) {
  const mappedContactTypes =
    contactTypes &&
    contactTypes.map((contactType: { level: string; roleArea: string }) => {
      const { level, roleArea } = contactType;
      return (
        <div key={`contact-type-${roleArea}`} style={{ display: 'flex', marginTop: '0.5rem', marginRight: '0.5rem' }}>
          <span>
            <b>{level !== GENERAL_LEVEL_VALUE ? t(capitalize(level)) : ''}</b> {t('Tire')}{' '}
            {getOptionLabel('contact_types', roleArea)}
            {/* {getContactTypesRoleAreaLabelFromValue(roleArea, t)} */}
            <br />
          </span>
          <Checkbox disableFocusRipple disableRipple disableTouchRipple style={{ padding: 0, visibility: 'hidden' }} />
        </div>
      );
    });

  return mappedContactTypes && mappedContactTypes.length > 0 ? (
    <div style={{ marginTop: '0.8rem' }}>{mappedContactTypes}</div>
  ) : (
    <p>{t(nothingAssignedMessage)}</p>
  );
}

export function contactTypesRenderForTreeList(
  location: IAssigneeCustomerType,
  contactTypePerLocation: Map<string, any>,
  t: TFunction,
): JSX.Element {
  if (location && location.hash_key) {
    const contactTypes: Array<{ level: string; roleArea: string }> = contactTypePerLocation.get(
      location.hash_key.toString(),
    );
    return internalRendering(contactTypes, t);
  }

  return <p>{t(nothingAssignedMessage)}</p>;
}

interface GlobalActiosProps {
  fleetId: string;
  selectedRows: ContactListingOVM[];
  createPermission: boolean;
  deletePermission: boolean;
  massiveDelete: boolean;
  overrideDeletePermission: boolean;
  handleClickDelete: () => void;
  createContact: () => void;
  loadTable: () => void;
  clearRows: () => void;
  disableButtons?: boolean;
}
export function ContactGlobalActions({
  fleetId,
  selectedRows,
  createPermission,
  deletePermission,
  massiveDelete,
  overrideDeletePermission,
  handleClickDelete,
  createContact,
  loadTable,
  clearRows,
  disableButtons = false,
}: GlobalActiosProps) {
  const { t } = useTranslation();
  const saveMutation = useMutation(deleteContacts);
  const { confirmDialog, alertDialog } = useDialogs();
  const [saving, setSaving] = useState(false);

  return (
    <GlobalActions>
      <Fab color="inherit" aria-label={t('Saving')} id="savingContactButton" disabled={!saving}>
        <Timelapse />
      </Fab>
      {overrideDeletePermission ? (
        <Fab
          color="primary"
          aria-label={t('Delete')}
          disabled={!deletePermission || saving || disableButtons}
          displayDisabled={disableButtons}
          onClick={async () => {
            if (massiveDelete && selectedRows.length > 0) {
              setSaving(true);
              try {
                // const primaryOrSecondaryLevel = selectedRows.map((contact) => contact.contact_types?.map((contactType) => contactType.role_areas?.map((rolArea) => rolArea.levels.filter((level) => level.level === PRIMARY_LEVEL_VALUE || SECONDARY_LEVEL_VALUE).length > 0).includes(true)).includes(true)).filter((val) => val).length;
                // const tireManagerOrIssuerType = selectedRows.map((contact) => contact.contact_types?.filter((contactType) => contactType.service === 'tire').map((contactType) => contactType.role_areas?.map((rolArea) => rolArea.role_area === ERS_MANAGER_ROLE_AREA_VALUE || rolArea.role_area === PO_ISSUER_ROLE_AREA_VALUE).includes(true)).includes(true)).filter((val) => val).length;

                const affectedContacts: string[] = [];
                selectedRows.forEach((contact: ContactListingOVM) => {
                  contact.contact_types?.forEach((contactType) => {
                    if (contactType.service === 'tire') {
                      contactType.role_areas?.forEach((roleArea) => {
                        // check for ERS manager & PO Issuer
                        if (
                          roleArea.role_area === ERS_MANAGER_ROLE_AREA_VALUE ||
                          roleArea.role_area === PO_ISSUER_ROLE_AREA_VALUE
                        ) {
                          roleArea.levels?.forEach((level) => {
                            // check for Primary or Secondary
                            if (level.level === PRIMARY_LEVEL_VALUE || level.level === SECONDARY_LEVEL_VALUE) {
                              if (contact.id && affectedContacts.indexOf(contact.id) === -1)
                                affectedContacts.push(contact.id);
                            }
                          });
                        }
                      });
                    }
                  });
                });

                if (affectedContacts.length > 0) {
                  confirmDialog(
                    t(
                      '{{contactsCount}} contacts are assigned primary and/or secondary priority levels required for ONCall Tire ERS Services at one or more locations. Are you sure you want to delete these contacts?',
                      { contactsCount: affectedContacts.length },
                    ),
                    t("You're about to delete a contact."),
                    t('Continue'),
                    t('Cancel'),
                    async () => {
                      await saveMutation.mutateAsync({
                        fleetId: fleetId,
                        ids: selectedRows.map((contact) => contact.id || ''),
                      });
                      loadTable();
                      // enqueueSnackbar(t('Commercial Products information saved.'), { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'center' } });
                      handleClickDelete();
                      setSaving(false);
                    },
                    () => {
                      clearRows();
                      handleClickDelete();
                      setSaving(false);
                    },
                    undefined,
                  );
                } else {
                  confirmDialog(
                    t('Are you sure you want to delete these {{contactsCount}} contacts?', {
                      contactsCount: selectedRows.length,
                    }),
                    t("You're about to delete a contact."),
                    t('Continue'),
                    t('Cancel'),
                    async () => {
                      await saveMutation.mutateAsync({
                        fleetId: fleetId,
                        ids: selectedRows.map((contact) => contact.id || ''),
                      });
                      loadTable();
                      handleClickDelete();
                      setSaving(false);
                    },
                    () => {
                      clearRows();
                      handleClickDelete();
                      setSaving(false);
                    },
                    undefined,
                  );
                }
              } catch (error) {
                alertDialog(
                  t('Please check your connection and try again.\nIf the issue persist, please contact support').split(
                    '\n',
                  ),
                  t('Error saving Commercial Products Data.'),
                );
              }
            } else {
              handleClickDelete();
            }
          }}
        >
          <Delete />
        </Fab>
      ) : null}
      <Fab
        color="primary"
        aria-label={t('Back')}
        onClick={() => {
          handleClickDelete();
          clearRows();
        }}
        disabled={!massiveDelete}
      >
        <ArrowBack />
      </Fab>
      <Fab
        color="primary"
        aria-label={t('Add')}
        disabled={!createPermission || massiveDelete || disableButtons}
        displayDisabled={disableButtons}
        onClick={createContact}
      >
        <Add />
      </Fab>
    </GlobalActions>
  );
}
