import { MaestroAPI, maestroApiCall } from '@michelin/central-provider';
import { MethodType } from '@michelin/central-provider/dist/helpers/createAPICall';
import { useQuery } from '@tanstack/react-query';
import GLOBAL_CONFIG from '../global-variables';

const baseURL = GLOBAL_CONFIG.externalAPIUrl;

export async function deleteContacts({ fleetId, ids }: { fleetId: string; ids: string[] }) {
  const apiType: MaestroAPI = { method: MethodType.DELETE, endpoint: `cp-be/contacts/${fleetId}`, baseURL };

  const response = await maestroApiCall(apiType, { contact_ids: ids.join(',') }).then((res) => res.data);
  return response;
}

export interface primSecPriorityLevels {
  tire: {
    ers_manager: {
      primary: boolean;
      secondary: boolean;
    };
    po_issuer: {
      primary: boolean;
      secondary: boolean;
    };
  };
}
// Get warning about primary or secondary priority levels.
const getPrimSecWarning = async (fleetId: string, level: string): Promise<primSecPriorityLevels | null> => {
  if (!fleetId) return null;
  if (level.toLowerCase() !== 'st' && level.toLowerCase() !== 'bt') return null;
  const apiType: MaestroAPI = {
    method: MethodType.GET,
    endpoint: `cp-be/contacts/${fleetId}/warnings`,
    baseURL: baseURL || '',
  };
  let response = await maestroApiCall<primSecPriorityLevels>(apiType);
  return response;
};
export function useGetPrimSecWarning({ fleetId, level }: { fleetId: string; level: string }) {
  return useQuery(['primarySecundaryPriorityLevels', fleetId || ''], () => {
    return getPrimSecWarning(fleetId, level);
  });
}
